import { AwsRum } from "aws-rum-web";

let awsRum;
try { 
  const config = {
    sessionSampleRate: 1,
    identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
    endpoint: process.env.REACT_APP_AWS_RUM_ENDPOINT,
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };
  const APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
  const APPLICATION_VERSION = process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION;
  const APPLICATION_REGION = process.env.REACT_APP_AWS_RUM_APPLICATION_REGION;
  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
  console.log("Error initializing CloudWatch RUM web client", error.message);
}

export default awsRum;
