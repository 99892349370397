import React, { useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import axios from "axios";

import { signIn } from "../../hooks/signin";
import AppNavBar from "../../components/navbar";
import MessagePopup from "../../components/messagePopup";
import Cookies from "js-cookie";
import { signout } from "../../hooks/signout.js";

import "./style.css";
import { isEmail } from "validator";

export const SignInPage = ({ token, setToken }) => {
  //   const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");

  // deleting cookie will not work as it is a http only cookie which can't be acces on the client side and it can be handled only on server side.
  const deleteCookie = (name) => {
    Cookies.remove(name);
    // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // delete cookie (last django session)
    // deleteCookie('sessionid')
    // deleteCookie('csrftoken')
    // this is to remove session id from the server side as we cannot remove it from client side, its a http only cookie.

    if (!isEmail(email)) {
      setEmailError(() => "Invalid Email Address.");
      return;
    }

    await signout();
    localStorage.removeItem("token");
    localStorage.removeItem("orgId");
    localStorage.removeItem("refresh_token");
    // get token and signin
    try {
      const response = await signIn({ email, password });
      if (response.token) {
        localStorage.setItem("Response", JSON.stringify(response));

        // save username if required
        if (rememberMe) {
          //   localStorage.setItem("username", username);
          localStorage.setItem("email", email);
          localStorage.setItem("rememberMe", rememberMe);
        } else {
          //   localStorage.removeItem("username");
          localStorage.removeItem("email");
          localStorage.removeItem("rememberMe");
        }

        localStorage.setItem("token", response.token);
        setToken(response.token);
        localStorage.setItem("username", response.username);
        setToken(response.username);
        localStorage.setItem("userId", response.user_id);
        setToken(response.userId);
        localStorage.setItem("role", response.role);
        localStorage.setItem("refresh_token", response.refresh);
        if(sessionStorage.getItem('panelLink')){
          window.location.href = sessionStorage.getItem('panelLink');
        }
        else{
          window.location.href = "/";
        }
      }
    } catch (error) {
      updateMessage("error", error.message);
    }
  };

  useEffect(() => {
    // if saved token, skip signin
    if (token) {
      window.location.href = "/";
    }
  }, [token]);

  useEffect(() => {
    // check whether saved username
    // const previousUsername = localStorage.getItem("username");
    const previousEmail = localStorage.getItem("email");
    const previousRememberMe = localStorage.getItem("rememberMe");
    if (previousRememberMe) {
      //   setUsername(previousUsername);
      setEmail(previousEmail);
      setRememberMe(previousRememberMe);
    }
  }, []);

  // message window content
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  return (
    <>
      {localStorage.getItem("token") && <AppNavBar />}
      <Container className="mt-5 signin-wrapper">
        <div>
          <h2 className="mb-2 signin-text">ENSARAS</h2>
          <p className="mb-5 signin-p">
            Please enter your details to access your account
          </p>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group
              controlId="formBasicEmail"
              className="form-field signin-sect"
            >
              <Form.Label className="signin-text signin-field">
                Username
              </Form.Label>
              <Form.Control
                type="username"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                isInvalid={emailError}
                required
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => {
                  setEmailError("");
                  setEmail(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="form-field signin-sect"
            >
              <Form.Label className="signin-text signin-field">
                Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <div className="signin-box-container signin-sect">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
              </Form.Group>
              <a href="/reset-password" className="fgt-pwd">
                Forgot Password?
              </a>
            </div>

            <Button
              variant="primary"
              type="submit"
              block
              className="signin-sect"
            >
              Sign In
            </Button>
          </Form>
        </div>
      </Container>

      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
    </>
  );
};
