import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { editUser } from "../../hooks/userEdit.js";
import { getUser } from "../../hooks/userInfo.js";
import { getRawTeamInfo } from "../../hooks/teamInfo.js";
import "./style.css";
import { isAlpha, isAlphanumeric, isEmail } from "validator";
import { Reminder } from "../../utils/validation.js";

const UserEditModal = ({
  show,
  onHide,
  userId,
  orgId,
  getOrgInfo,
  updateMessage,
}) => {
  const [teams, setTeams] = useState([]);
  const [teamsInfo, setTeamsInfo] = useState(null);

  // const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [teamIds, setTeamIds] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [canEditOrDelete, setCanEditOrDelete] = useState(false)
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    teamIds: "",
  });

  // get user info
  useEffect(() => {
    if (userId) {
      getUser(userId)
        .then((response) => {
          const team_ids = response.data[0].team_ids;
          // setUsername(response.data[0].username);
          setFirstName(response.data[0].first_name);
          setLastName(response.data[0].last_name);
          setEmail(response.data[0].email);
          setTeamIds(team_ids);
          setIsAdmin(response.data[0].is_admin);
          //   setCanEditOrDelete(response.data[0].can_edit_delete);
          setCanEdit(response.data[0].can_edit);
          setCanDelete(response.data[0].can_delete);

          // if include special teams, update team list
          const allTeams = teamsInfo.reduce(
            (pre, item) => pre.concat(item.teams),
            []
          );
          const specialTeamObjects = allTeams.filter((team) =>
            specialTeams.includes(team.teamName)
          );

          if (
            specialTeamObjects.some((team) => team_ids.includes(team.teamId))
          ) {
            const allTeams = teamsInfo.reduce(
              (pre, item) => pre.concat(item.teams),
              []
            );
            setTeams(allTeams);
          }
        })
        .catch((error) => {
          updateMessage("error", `[${error.message}] Failed to edit user!`);
        });
    }
  }, [userId, show, teamsInfo]);

  // get all teams info
  useEffect(() => {
    getRawTeamInfo()
      .then((response) => {
        setTeamsInfo(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [show]);

  // first show the current org
  useEffect(() => {
    if (teamsInfo && orgId) {
      const currentOrg = teamsInfo.find((item) => item.orgId === orgId);
      setTeams(currentOrg.teams);
    }
  }, [orgId, teamsInfo]);

  const handleSubmit = () => {
    // validation for data
    const errorStatus = { ...error };

    if (!firstName.trim()) {
      errorStatus.firstName = "First name is required.";
    }
    if(!lastName.trim()) {
      errorStatus.lastName = "Last name is required.";
    }
    const nameRegex = /^[a-zA-Z'_ -]+$/;
    if (firstName && !nameRegex.test(firstName)) {
      errorStatus.firstName =
        "First name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    if (lastName && !nameRegex.test(lastName)) {
      errorStatus.lastName =
        "Last name can only contain letters, hyphens, apostrophes, spaces, and underscores.";
    }
    // if (!email.trim()) {
    //   errorStatus.email = "Email is required.";
    // }
    // if (email && !isEmail(email)) {
    //   errorStatus.email = "Email is invalid.";
    // }
    if (
      password.trim() &&
      !password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]).{8,}$/
      )
    ) {
      if (password.trim() === "") {
        errorStatus.password =
          "Password must have at least 8 characters and contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.";
      } else if (password.length < 8) {
        errorStatus.password = "Password must have at least 8 characters.";
      } else if (!password.match(/[a-z]/)) {
        errorStatus.password =
          "Password must have at least 1 lowercase letter.";
      } else if (!password.match(/[A-Z]/)) {
        errorStatus.password =
          "Password must have at least 1 uppercase letter.";
      } else if (!password.match(/\d/)) {
        errorStatus.password = "Password must have at least 1 number.";
      } else if (!password.match(/[a-zA-Z]/)) {
        errorStatus.password = "Password must have at least 1 letter.";
      } else if (!password.match(/[!@#$%^&*()_+[\]{};':"\\|,.<>\/?]/)) {
        errorStatus.password =
          "Password must have at least 1 special character.";
      }
    }
    if (teamIds.length === 0) {
      errorStatus.teamIds = "Please select a Team.";
    }

    if (Object.values(errorStatus).some((value) => value !== "")) {
      setError(() => errorStatus);
      return;
    }

    editUser({
      userId,
      orgId,
      // username,
      first_name: firstName,
      last_name: lastName,
      // email,
      password,
      teams: teamIds,
      is_admin: isAdmin,
      //   can_edit_delete: canEditOrDelete,
      can_edit: isAdmin ? true : canEdit,
      can_delete: isAdmin ? true : canDelete,
    }).then((response) => {
      // Close the modal
      handleModalHide();

      getOrgInfo();
    });
  };

  // special teams
  const specialTeams = ["Project Analytics", "Engineer"];

  const handleMultipleChoices = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );

    const selectedOptionNames = Array.from(
      e.target.selectedOptions,
      (option) => option.text
    );
    // if include special teams, update team list
    if (specialTeams.some((value) => selectedOptionNames.includes(value))) {
      const allTeams = teamsInfo.reduce(
        (pre, item) => pre.concat(item.teams),
        []
      );
      setTeams(allTeams);
    } else {
      const currentOrg = teamsInfo.find((item) => item.orgId === orgId);
      setTeams(currentOrg.teams);
    }
    setTeamIds(selectedOptions);
  };

  const handleModalHide = () => {
    setError(() => ({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      teamIds: "",
    }));
    setPassword("");
    onHide();
  };

  return (
    <Modal show={show} onHide={handleModalHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group> */}

          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              isInvalid={!!error.firstName}
              required
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              onChange={(e) => {
                setError({ ...error, firstName: "" });
                setFirstName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {error.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              isInvalid={!!error.lastName}
              required
              type="text"
              placeholder="Enter Last Name"
              value={lastName}
              onChange={(e) => {
                setError({ ...error, lastName: "" });
                setLastName(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {error.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              isInvalid={!!error.email}
              required
              disabled
              placeholder="Enter Email Address"
              value={email}
              onChange={(e) => {
                setError({ ...error, email: "" });
                setEmail(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {error.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              isInvalid={!!error.password}
              required
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => {
                setError({ ...error, password: "" });
                setPassword(e.target.value);
              }}
            />
            {!error.password && (
              <Form.Text className="text-muted">
                {Reminder("password")}
              </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
              {error.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Team</Form.Label>
            <Form.Select
              isInvalid={!!error.teamIds}
              required
              multiple
              value={teamIds}
              onChange={handleMultipleChoices}
            >
              {teams &&
                teams.map((team) => (
                  <option key={team.teamName} value={team.teamId}>
                    {team.teamName}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {error.teamIds}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Is Admin"
              checked={isAdmin}
              onChange={() => {
                setIsAdmin(!isAdmin);
              }}
            />
          </Form.Group>

          {/* <Form.Group>
            <Form.Check
              type="checkbox"
              label="Can Edit Or Delete"
              checked={canEditOrDelete}
              onChange={() => {
                setCanEditOrDelete(!canEditOrDelete);
              }}
            />
          </Form.Group> */}

          <Form.Group>
            <Form.Check
              type="checkbox"
              disabled={isAdmin}
              label="Can Edit"
              checked={isAdmin ? true : canEdit}
              onChange={() => {
                setCanEdit(!canEdit);
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              disabled={isAdmin}
              label="Can Delete"
              checked={isAdmin ? true : canDelete}
              onChange={() => {
                setCanDelete(!canDelete);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserEditModal;
